import React, { useEffect, useState } from "react";
import { auth } from "../firebase.js";
import {
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  UserCredential,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import * as FcIcons from "react-icons/fc";
import "./login.css";
import axios from "axios";
import Loader from "../components/Loader.jsx";
import { toast } from "react-toastify";
import * as MdIcons from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { onValidEmailAddress } from "../api/DashboardHandler";

const coreURL = `${process.env.REACT_APP_CORE_API_URL}/getUserDetailsByFeduid`;

function Login({ setIsLoggedIn, isLoggedIn }) {
  const [userLoading, setUserLoading] = useState(false);

  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  });

  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);

  const [emailValid, setEmailValid] = useState(true);
  const [passValid, setPassValid] = useState(true);

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "email") {
      if (!onValidEmailAddress(value)) {
        setEmailValid(false);
      } else {
        setEmailValid(true);
      }
    }

    if (name === "password") {
      if (value.length < 8) {
        setPassValid(false);
      } else {
        setPassValid(true);
      }
    }

    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleForgotPassword = () => {
    setShowResetPassword(true);
  };

  const goBackToSignIn = () => {
    setShowResetPassword(false);
  };

  const resetPassword = () => {
    if (!onValidEmailAddress(userInfo.email)) {
      setEmailValid(false);
      return;
    }
    setUserLoading(true)
    setEmailValid(true);
    sendPasswordResetEmail(auth, userInfo.email)
      ?.then(() => {
        setUserLoading(false)
        toast.success(`Password reset link sent to ${userInfo.email}!`);
      })
      ?.catch((error) => {
        setUserLoading(false)
        toast.error(error?.errors?.toString());
      });
  };

  useEffect(() => {
    sessionStorage.clear();
    if (isLoggedIn) {
      setIsLoggedIn(false);
    }
  }, []);

  const handleGoogleSignIn = async () => {
    setUserLoading(true);
    const provider = new GoogleAuthProvider();
    try {
      let res = await signInWithPopup(auth, provider);
      let usr = res.user;
      let token = "";
      await usr.getIdToken().then((idToken) => (token = idToken));
      sessionStorage.setItem("SKEY", token);
      const config = {
        headers: {
          SKEY: token,
        },
      };
      verifyUserRole({ usr: usr, config: config });
    } catch (error) {
      setUserLoading(false);
    }
  };

  const loginInWithEmailAndPassword = async () => {
    if (!userInfo.email) {
      toast.warning("Email is required or invalid");
      return;
    }
    if (!userInfo.password) {
      toast.warning("Password is required or invalid");
      return;
    }
    setUserLoading(true);
    signInWithEmailAndPassword(auth, userInfo.email, userInfo.password)
      ?.then(async (user: UserCredential) => {
        let usr = user.user;
        let token = "";
        await usr.getIdToken().then((idToken) => (token = idToken));
        sessionStorage.setItem("SKEY", token);
        const config = {
          headers: {
            SKEY: token,
          },
        };
        verifyUserRole({ usr: usr, config: config });
      })
      .catch((error) => {
        toast.warning("Ooops, Invalid credentials");
        setUserLoading(false);
      });
  };

  const verifyUserRole = ({ usr, config }: { usr: any; config: any }) => {
    axios
      .post(coreURL, { feduid: usr.uid }, config)
      .then((response) => {
        if (response.data.Status === 200) {
          let roles = response.data.Payload.roles;
          let userName = response.data.Payload.userName;
          let admin = roles.some((role) =>
            Object.prototype.hasOwnProperty.call(role, "admin")
          );
          if (admin && roles.some((role) => role.admin)) {
            sessionStorage.setItem("admin", JSON.stringify(true));
            sessionStorage.setItem("userName", userName);
            setIsLoggedIn(true);
            setUserLoading(false);
            navigate("/");
          } else {
            toast.error("Access denied! Please contact administrator.");
            setUserLoading(false);
          }
        } else {
          toast.error("Login failed please try again!");
          setUserLoading(false);
        }
      })
      .catch((error) => {
        setUserLoading(false);
        toast.error("Login failed please try again!");
        return;
      });
  };

  useEffect(() => {}, []);

  return (
    <div className="signIn">
      {!showResetPassword ? (
        <div className="centered-button-container">
          {userLoading && <Loader />}
          {!userLoading && (
            <button
              className="btn btn-secondary btn-lg btn-login"
              onClick={(e) => handleGoogleSignIn()}
            >
              <span>
                <FcIcons.FcGoogle />
              </span>{" "}
              Sign in with Google
            </button>
          )}
          <div className="line-container mt-3">
            <span className="word-between-lines">OR</span>
          </div>
          <div className="auth-input-container">
            <div className="auth-input-icon">
              <MdIcons.MdEmail />
            </div>
            <div className="label">Your email address</div>
            <input
              type="text"
              placeholder="email"
              name="email"
              value={userInfo.email}
              onChange={handleInputChange}
            />
            {!emailValid && (
              <div className="error-msg mt-1">
                <MdIcons.MdError />
                <span className="ms-1">Please enter a valid email</span>
              </div>
            )}
          </div>
          <div className="auth-input-container">
            <div className="auth-input-icon">
              <RiLockPasswordFill />
            </div>

            <div className="label">Password</div>
            <input
              type="password"
              placeholder="password"
              name="password"
              value={userInfo.password}
              onChange={handleInputChange}
            />
            {!passValid && (
              <div className="error-msg mt-1">
                <MdIcons.MdError />
                <span className="ms-1">
                  Password must be more than 8 characters
                </span>
              </div>
            )}
          </div>
          <div className="auth-input-container bottom-text-container d-flex justify-content-end align-items-end">
            <span
              onClick={(e) => handleForgotPassword()}
              className="forgot-txt"
            >
              Forgot your password?
            </span>
          </div>
          <div className="auth-input-container mt-4">
            {userLoading ? (
              <Loader />
            ) : (
              <button
                className="btn btn-primary"
                onClick={() => loginInWithEmailAndPassword()}
              >
                Sign In
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="centered-button-container">
          <div className="auth-input-container text-center">
            <strong>Request Reset password link</strong>
          </div>
          <div className="auth-input-container">
            <div className="auth-input-icon">
              <MdIcons.MdEmail />
            </div>
            <div className="label">Your email address</div>
            <input
              type="text"
              placeholder="email"
              name="email"
              value={userInfo.email}
              onChange={handleInputChange}
            />
            {!emailValid && (
              <div className="error-msg mt-1">
                <MdIcons.MdError />
                <span className="ms-1">Please enter a valid email</span>
              </div>
            )}
          </div>
          <div className="auth-input-container bottom-text-container d-flex justify-content-end align-items-end">
            <span onClick={(e) => goBackToSignIn()} className="forgot-txt">
              Sign In
            </span>
          </div>
          <div className="auth-input-container mt-4">
            {userLoading ? (
              <Loader />
            ) : (
              <button
                className="btn btn-primary btn-lg"
                onClick={resetPassword}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default Login;
