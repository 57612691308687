import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDYZcTmUhossqtk49Ok7IAVv70mbtRrb8g",
  authDomain: "mtek-9fb22.firebaseapp.com",
  databaseURL: "https://mtek-9fb22.firebaseio.com",
  projectId: "mtek-9fb22",
  storageBucket: "mtek-9fb22.appspot.com",
  messagingSenderId: "225875384266",
  appId: "1:225875384266:web:68e8133414247fcffee133",
  measurementId: "G-7SC94TZJJL",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const logout = () => {
  auth.signOut();
};

export { auth, logout };
