import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../sidebar/Sidebar'

function BaseLayout(sasPartners) {
  return (
    <>
    <Sidebar sasPartners={sasPartners?.sasPartners} />
    <section className='section'>
       <Outlet />
    </section>
    </>
  )
}

export default BaseLayout