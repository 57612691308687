import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled.div`
  display: flex;
  color: #495057;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  list-style: none;
  height: 40px;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 600;
  &:hover {
    background: #5550bd;
    cursor: pointer;
    color: white;
  }
`;

const RootLink = styled.div`
  text-decoration: "none";
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  background: #fff;
  height: 35px;
  padding-left: 1.5rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #495057;
  font-size: 0.8rem;

  &:hover {
    background: #5550bd;
    cursor: pointer;
    color: white;
    border-radius: 12px;
  }
`;

const ChildrenDropdownLink = styled(Link)`
  background: #fff;
  height: 35px;
  padding-left: 1.5rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #495057;
  font-size: 0.8rem;

  &:hover {
    background: #5550bd;
    cursor: pointer;
    color: white;
    border-radius: 12px;
  }
`;

const SubMenu = ({ item }) => {
  const [openSubNav, setOpenSubNav] = useState(null); // Track the open subNav
  const [openSubNavChildren, setOpenSubNavChildren] = useState(null); // Track open subNavChildren

  const toggleSubNav = (item) => {
    if (openSubNav === item.title) {
      setOpenSubNav(null); // Close if it's already open
    } else {
      setOpenSubNav(item.title); // Open the clicked subNav
    }
  };

  const toggleSubNavChildren = (subItem) => {
    if (openSubNavChildren === subItem.title) {
      setOpenSubNavChildren(null); // Close if already open
    } else {
      setOpenSubNavChildren(subItem.title); // Open clicked subNavChildren
    }
  };

  return (
    <>
      <SidebarLink onClick={item.subNav ? () => toggleSubNav(item) : null}>
        {item.path ? (
          <RootLink>
            <Link to={item.path} className="root-link">
              {item.icon}
              <SidebarLabel>{item.title}</SidebarLabel>
            </Link>
          </RootLink>
        ) : (
          <div>
            {item.icon}
            <SidebarLabel>{item.title}</SidebarLabel>
          </div>
        )}
        <div>
          {item.subNav && openSubNav === item.title
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {openSubNav === item.title &&
        item.subNav.map((subItem, index) => (
          <div key={index}>
            <DropdownLink
              to={subItem.path}
              onClick={() => toggleSubNavChildren(subItem)}
            >
              {subItem.icon}
              <SidebarLabel>{subItem.title}</SidebarLabel>
            </DropdownLink>
            <div className="subnav-children">
              {openSubNavChildren === subItem.title &&
                subItem.subNavChildren?.map((childNav, childIndex) => (
                  <ChildrenDropdownLink to={childNav.path} key={childIndex}>
                    {childNav.icon}
                    <SidebarLabel>{childNav.title}</SidebarLabel>
                  </ChildrenDropdownLink>
                ))}
            </div>
          </div>
        ))}
    </>
  );
};

export default SubMenu;
