export const convertToTimeStamp = (timestamp) => {
  const date = new Date(timestamp);
  const formattedDate = date.toUTCString().slice(5, 16);
  return formattedDate;
};

export const onValidEmailAddress = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

