import { toast } from "react-toastify";
import { logout } from "../firebase.js";
import * as XLSX from "xlsx";

export const makePostRequest = async (
  apiUrl: string,
  body: any
): Promise<IApiResponse | undefined> => {
  try {
    const apiResponse = await fetch(apiUrl, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        //    ...headers,
        SKEY: sessionStorage.getItem("SKEY")!,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data: IApiResponse = await apiResponse.json();
    return data;
  } catch (error: any) {
    if (error) {
      return error;
    }
  }
};

export const makeBymStatsPostRequest = async ({
  apiUrl,
  body,
  apiKey,
}: {
  apiUrl: string;
  body: any;
  apiKey: string;
}): Promise<IApiResponse | undefined> => {
  try {
    const apiResponse = await fetch(apiUrl, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        apiKey: apiKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data: IApiResponse = await apiResponse.json();
    return data;
  } catch (error: any) {
    if (error) {
      return error;
    }
  }
};


export const makeSaasPostRequest = async ({
  apiUrl,
  daterange,
  apiKey,
  useCase,
}: {
  apiUrl: string;
  daterange: any;
  apiKey: string;
  useCase: string;
}) => {
  let query = {};
  if (daterange?.type == "policy" || !daterange?.type) {
    if (useCase == "mia") {
      query = {
        salesDateTimestamp: {
          $gte: daterange.startDate,
          $lte: daterange.endDate,
        },
        paidamount: {
          $gt: 0,
        },
      };
    } else if (useCase == "banca") {
      query = {
        dateField: "salesDateTimestamp",
        sdate: daterange.startDate,
        edate: daterange.endDate,
        ispolicy: true,
      };
    }
  } else if (daterange?.type == "quote") {
    if (useCase == "mia") {
      query = {
        _timestamp: {
          $gte: daterange.startDate,
          $lte: daterange.endDate,
        },
        $or: [{ paidamount: 0 }, { paidamount: { $exists: false } }],
      };
    } else if (useCase == "banca") {
      query = {
        dateField: "_timestamp",
        sdate: daterange.startDate,
        edate: daterange.endDate,
        ispolicy: false,
      };
    }
  }

  let body = {};
  if (useCase == "mia") {
    body = {
      match: {
        ...query,
      },
      idFields: [],
    };
  } else {
    body = {
      match: {},
      ...query,
      idFields: [],
    };
  }

  try {
    const apiResponse = await fetch(apiUrl, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        //    ...headers,
        apiKey: apiKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data: IApiResponse = await apiResponse.json();
    return data;
  } catch (error: any) {
    if (error) {
      return error;
    }
  }
};
export const processHttpErrors = (err: any) => {
  let e = err?.error;
  if (err.Status == 543) {
    sessionStorage.clear();
    logout();
    return `Session Expired. Log in afresh`;
  } else if (e && "Payload" in e) {
    // Check if e is defined before checking 'Payload'
    if (typeof e.Payload === "string") {
      return e.Message;
    }
  } else {
    if (e?.code) {
      return `Status (${e?.code}) : ${e?.message} ${e.error}`;
    } else {
      return `Status (${err.status}) : ${err.statusText}`;
    }
  }
};

export const exportToExcell = ({
  data,
  fileName,
}: {
  data: any[];
  fileName: string;
}) => {
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

  // Create a header row and set the style
  const header = Object.keys(data[0]);
  header.forEach((key, index) => {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
    if (!ws[cellAddress]) {
      ws[cellAddress] = { v: key };
    }
  });

  // Set the style for the header row
  ws["!cols"] = ws["!cols"] || [];
  header.forEach(() => {
    ws["!cols"]!.push({ width: 20 }); // Adjust the width as needed
  });
  // Create a workbook and add the worksheet
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Save the file
  XLSX.writeFile(wb, fileName + ".xlsx");
};


export const getSaasData = async ({
  daterange,
  url,
  apiKey,
  useCase,
}: {
  daterange: any;
  url: string;
  apiKey: string;
  useCase: string;
}): Promise<any[] | undefined> => {
  try {
    let bymDataResponse = await makeSaasPostRequest({
      daterange: daterange,
      apiUrl: url,
      apiKey: apiKey,
      useCase: useCase,
    });
    if (bymDataResponse.Status == 200) {
      let data: any[] = bymDataResponse.Payload;
      let newFormatedData: any[] = data.map((policyStat) => {
        let stat: any = {};
        stat = { ...policyStat };
        stat["third_party_name"] = stat._id.policy;
        delete stat._id;
        let sortedStat: any = Object.fromEntries(Object.entries(stat).sort());
        let sortedStartingWithPolicy: any = {
          policy: sortedStat.policy,
          ...sortedStat,
        };
        return sortedStartingWithPolicy;
      });
      return newFormatedData;
    } else {
      toast.error(bymDataResponse.Payload);
    }
  } catch (error: any) {
    toast.error(error);
  }
};