import React from "react";

function Loader() {

  return (
    <div className="loader-container">
        <div className="dots-bars-5"></div>
    </div>
  );
}
export default Loader;
